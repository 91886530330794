import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import BetGames from '../../components/BetGames/BetGames';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <BetGames></BetGames>
      </IonContent>
    </IonPage>
  );
};

export default Home;
