import React from 'react';
import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/react';
import {
  constructOutline,
  bookOutline,
  logOutOutline,
  trophyOutline,
} from 'ionicons/icons';

import { NavItem } from '../../models/NavItem';
import { toggleDrawer, logout } from '../../store';
import './NavList.scss';

type PropType = {
  navListItems: NavItem[];
  logout: Function;
  toggleDrawer: Function;
};

const NavList: React.FC<PropType> = (props) => {
  const { navListItems, logout, toggleDrawer } = props;

  const iconMap: { [key: string]: string } = {
    'trophy-outline': trophyOutline,
    'book - outline': bookOutline,
    'construct-outline': constructOutline,
    'log-out-outline': logOutOutline,
  };

  const getIcon = (icon: string) => {
    return iconMap[icon];
  };

  const handleDrawer = () => {
    toggleDrawer(false);
  };

  const logoutHandler = () => {
    toggleDrawer(false);
    logout();
  };

  return (
    <IonList class="nav-list">
      {navListItems.map((item) => (
        <NavLink
          activeClassName="active"
          to={item.path}
          key={item.name}
          className="nav-link"
          onClick={item.name === 'Logout' ? logoutHandler : handleDrawer}
        >
          <IonItem button className="list-item">
            <IonIcon icon={getIcon(item.image)}></IonIcon>
            <IonLabel className="nav-label">{item.name}</IonLabel>
          </IonItem>
        </NavLink>
      ))}
    </IonList>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    toggleDrawer: (isDrawerOpen: boolean) =>
      dispatch(toggleDrawer(!isDrawerOpen)),
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(NavList);
