import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useParams } from 'react-router-dom';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';

import './SportView.scss';

const SportView: React.FC = () => {
  const { sid } = useParams();

  return (
    <IonPage className="sport-view-ctn">
      <IonContent>
        <IonCard className="add-banner">
          <IonCardHeader>
            <IonCardTitle>{sid}</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            In this page you can see all tournment and Markets of this
            particular sport.
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default SportView;
