import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/react';

import TableView from '../../components/Table/Table';
import API from '../../api/index';
import { logout } from '../../store';
import { UserAccount } from '../../models/UserAccount';
import { AuthResponse } from '../../models/api/AuthResponse';
import Modal from '../../components/Modal/Modal';
import InviteUser from '../../components/InviteUser/InviteUser';
import Spinner from '../../components/Spinner/Spinner';
import { personOutline } from 'ionicons/icons';
import { USERS_TB_HEADERS } from '../../constants/UserManagement';
import './UserManagement.scss';

type UsersProps = {
  logout: Function;
};

const Users: React.FC<UsersProps> = (props) => {
  const { logout } = props;
  const [errorMsg, setErrorMsg] = useState(null);
  const [progress, setProgress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle] = useState('Invite User');
  const [users, setUsers] = useState<UserAccount[]>([]);

  const modalHandler = () => {
    setModalOpen(true);
  };
  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  const inviteUserSuccessHandler = () => {
    setModalOpen(false);
    fetchRecords();
  };

  const fetchRecords = useCallback(async () => {
    setProgress(true);
    try {
      const response: AuthResponse = await API.get('/user/invites', {
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        setUsers(response.data);
        setProgress(false);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setProgress(false);
      if (err.response && err.response.status === 401) {
        logout();
      }
    }
  }, [logout]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <div>
      {progress ? <Spinner /> : ''}
      {errorMsg && !progress ? (
        <h4>{errorMsg}</h4>
      ) : (
        <div>
          <div className="page-header-ctn">
            <IonTitle>User Management</IonTitle>
            <IonButtons slot="secondary">
              <IonButton onClick={modalHandler}>
                <IonIcon icon={personOutline}></IonIcon> Invite
              </IonButton>
            </IonButtons>
          </div>

          {users && users.length > 0 ? (
            <TableView fields={USERS_TB_HEADERS} items={users} />
          ) : (
            <h5 className="no-users">You are not invited any users.</h5>
          )}
          <Modal
            open={modalOpen}
            closeHandler={modalCloseHandler}
            title={modalTitle}
          >
            <InviteUser
              inviteUserSuccessHandler={inviteUserSuccessHandler}
            ></InviteUser>
          </Modal>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Users);
