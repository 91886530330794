import React, { useState } from 'react';
import { IonIcon, IonSpinner } from '@ionic/react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { logInOutline } from 'ionicons/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import API from '../../api/index';
import { logout } from '../../store';
import { InviteUserParams } from '../../models/InviteUserParams';
import { AuthResponse } from '../../models/api/AuthResponse';

type InviteUserProps = {
  inviteUserSuccessHandler: Function;
};

const InviteUser: React.FC<InviteUserProps> = (props) => {
  const [progress, setProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { inviteUserSuccessHandler } = props;

  const [showPassword, setShowPassword] = useState(false);
  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      userRole: '',
      balance: '',
      balanceLimit: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
      balance: Yup.string().required('Required'),
      balanceLimit: Yup.string().required('Required'),
      userRole: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      let data: InviteUserParams = {
        username: values.username,
        password: values.password,
        balance: values.balance,
        balance_limit: values.balanceLimit,
        role: values.userRole,
      };
      inviteUser(data);
    },
  });

  const inviteUser = async (data: InviteUserParams) => {
    try {
      setProgress(true);
      const response: AuthResponse = await API.post('/user', data, {
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        inviteUserSuccessHandler();
        setProgress(false);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setProgress(false);
      if (err.response && err.response.status === 401) {
        logout();
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="login-form-ctn">
      <FormControl
        error={formik.touched.username && formik.errors.username ? true : false}
      >
        <InputLabel htmlFor="user-adornment-username">User Name</InputLabel>
        <Input
          id="user-adornment-Username"
          type="text"
          name="username"
          {...formik.getFieldProps('username')}
        />
        {formik.touched.username && formik.errors.username ? (
          <FormHelperText error id="my-helper-text">
            {formik.errors.username}
          </FormHelperText>
        ) : null}
      </FormControl>

      <FormControl
        className="login-input-field"
        error={formik.touched.password && formik.errors.password ? true : false}
      >
        <InputLabel htmlFor="user-adornment-password">Password</InputLabel>
        <Input
          id="user-adornment-password"
          type={showPassword ? 'text' : 'password'}
          name="password"
          {...formik.getFieldProps('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showPasswordClickHandler}
                onMouseDown={showPasswordClickHandler}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {formik.touched.password && formik.errors.password ? (
          <FormHelperText error id="my-helper-text">
            {formik.errors.password}
          </FormHelperText>
        ) : null}
      </FormControl>

      <FormControl
        className="formControl"
        error={formik.touched.userRole && formik.errors.userRole ? true : false}
      >
        <InputLabel id="user-role-select-label">Role</InputLabel>
        <Select
          name="userRole"
          labelId="user-role-select-label"
          id="user-role"
          {...formik.getFieldProps('userRole')}
        >
          <MenuItem value="Punter">Punter</MenuItem>
          <MenuItem value="Bookie">Bookie</MenuItem>
          <MenuItem value="Master">Master</MenuItem>
          <MenuItem value="Super master">Super Master</MenuItem>
        </Select>
        {formik.touched.userRole && formik.errors.userRole ? (
          <FormHelperText error id="my-helper-text">
            {formik.errors.userRole}
          </FormHelperText>
        ) : null}
      </FormControl>

      <FormControl
        className="login-input-field"
        error={formik.touched.balance && formik.errors.balance ? true : false}
      >
        <InputLabel>balance</InputLabel>
        <Input
          id="user-balance"
          type="number"
          name="balance"
          {...formik.getFieldProps('balance')}
        />
        {formik.touched.balance && formik.errors.balance ? (
          <FormHelperText error id="my-helper-text">
            {formik.errors.balance}
          </FormHelperText>
        ) : null}
      </FormControl>

      <FormControl
        error={
          formik.touched.balanceLimit && formik.errors.balanceLimit
            ? true
            : false
        }
      >
        <InputLabel>Balance Limit</InputLabel>
        <Input
          id="user-balance-limit"
          type="text"
          name="balanceLimit"
          {...formik.getFieldProps('balanceLimit')}
        />
        {formik.touched.balanceLimit && formik.errors.balanceLimit ? (
          <FormHelperText error id="my-helper-text">
            {formik.errors.balanceLimit}
          </FormHelperText>
        ) : null}
      </FormControl>

      {errorMsg !== '' ? (
        <span className="login-err-msg">{errorMsg}</span>
      ) : null}

      <Button
        className="login-form-btn"
        color="primary"
        endIcon={
          progress ? (
            <IonSpinner name="lines-small" />
          ) : (
            <IonIcon icon={logInOutline}></IonIcon>
          )
        }
        type="submit"
        variant="contained"
      >
        Invite
      </Button>
    </form>
  );
};

export default InviteUser;
