import React from 'react';

import { NavLink, useRouteMatch } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { IonList } from '@ionic/react';
import Avatar from '@material-ui/core/Avatar';

import { Sport } from '../../models/Sport';
import './SportsListNav.scss';

type PropType = {
  listItems: Sport[];
};

const SportsListNav: React.FC<PropType> = (props) => {
  const { listItems } = props;

  const dense = false;
  const { url } = useRouteMatch();

  return (
    <IonList class="sports-nav-list">
      <List dense={dense}>
        <ListSubheader component="div" id="nested-list-subheader">
          All Sports
        </ListSubheader>
        {listItems.map((item, index) => (
          <NavLink
            to={`${url}/${item.id}`}
            key={index}
            activeClassName="active"
            className="nav-link"
          >
            <ListItem button>
              <ListItemIcon>
                <Avatar className="avatar-icon"></Avatar>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </IonList>
  );
};

export default SportsListNav;
