import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonIcon, IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { logInOutline } from 'ionicons/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import './LoginForm.scss';
import { login, fetchBalance } from '../../store';
import { RootState } from '../../models/RootState';

type LoginProps = {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  login: Function;
  fetchBalance: Function;
  modalCloseHandler: Function;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      login(values.username, values.password);
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const {
    errorMsg,
    loading,
    loggedIn,
    login,
    fetchBalance,
    modalCloseHandler,
  } = props;

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
      modalCloseHandler();
    }
  }, [fetchBalance, loggedIn, modalCloseHandler]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="login-form-ctn">
      <TextField
        className="login-input-field"
        label="Username"
        type="text"
        name="username"
        error={formik.touched.username && formik.errors.username ? true : false}
        helperText={
          formik.touched.username && formik.errors.username
            ? formik.errors.username
            : null
        }
        {...formik.getFieldProps('username')}
      />
      <FormControl
        className="login-input-field"
        error={formik.touched.password && formik.errors.password ? true : false}
      >
        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
        <Input
          id="standard-adornment-password"
          type={showPassword ? 'text' : 'password'}
          name="password"
          {...formik.getFieldProps('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showPasswordClickHandler}
                onMouseDown={showPasswordClickHandler}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {formik.touched.password && formik.errors.password ? (
          <FormHelperText error id="my-helper-text">
            {formik.errors.password}
          </FormHelperText>
        ) : null}
      </FormControl>

      {errorMsg !== '' ? (
        <span className="login-err-msg">{errorMsg}</span>
      ) : null}

      <Button
        className="login-form-btn"
        color="primary"
        endIcon={
          loading ? (
            <IonSpinner name="lines-small" />
          ) : (
            <IonIcon icon={logInOutline}></IonIcon>
          )
        }
        type="submit"
        variant="contained"
      >
        Log In
      </Button>
    </form>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    login: (username: string, password: string) =>
      dispatch(login(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
