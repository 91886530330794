export const DRAWER_NAV_LIST = [
  {
    name: 'User Management',
    path: '/user_management',
    image: 'construct-outline',
  },
  {
    name: 'Ledger',
    path: '/ledger',
    image: 'book - outline',
  },
  {
    name: 'Logout',
    path: '/home',
    image: 'log-out-outline',
  },
];
