import { SportsBookState } from '../../models/SportsBookState';
import { SportEventInfoData } from '../../models/SportEventInfoData';
import {
  FEATCH_SPORTS_SUCCESS,
  MARKET_ODDS_SUCCESS,
  REQUEST_START,
  REQUEST_FAILED,
  ODDS_CHANGE,
  BET_STOP
} from './sportsBookActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initialState: SportsBookState = {
  errorMsg: '',
  sportEventInfoData: [],
  sports: [],
  loading: false,
};

const sportsReducer = (
  state = initialState,
  action: Action
): SportsBookState => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };
    case MARKET_ODDS_SUCCESS:
      return {
        ...state,
        sportEventInfoData: action.payload,
        loading: false,
        errorMsg: '',
      };
    case FEATCH_SPORTS_SUCCESS:
      return {
        ...state,
        sports: action.payload,
        loading: false,
        errorMsg: '',
      };
    case REQUEST_FAILED:
      return {
        ...state,
        errorMsg: action.payload,
        loading: false,
      };
    case ODDS_CHANGE:
      {
        const sportEventInfo: SportEventInfoData = action.payload;
        let sportEventInfoList = [...state.sportEventInfoData];
        const foundEventIndex = sportEventInfoList.findIndex(se => se.eventId === sportEventInfo.eventId);
        if (foundEventIndex !== -1) {
          if (sportEventInfo.marketOddsDataSet) {
            for (const md of sportEventInfo.marketOddsDataSet) {
              const foundMarketIndex = sportEventInfoList[foundEventIndex].marketOddsDataSet.findIndex(m => m.marketId === md.marketId);
              if (foundMarketIndex !== -1) {
                sportEventInfoList[foundEventIndex].marketOddsDataSet[foundMarketIndex] = md;
              } else {
                sportEventInfoList[foundEventIndex].marketOddsDataSet.push(md);
              }
            }
          }
        } else {
          sportEventInfoList.push(sportEventInfo);
        }

        return {
          ...state,
          sportEventInfoData: sportEventInfoList
        };
      }
    case BET_STOP: {
      let sportEventInfoList = [...state.sportEventInfoData];
      const foundEventIndex = sportEventInfoList.findIndex(se => se.eventId === action.payload.eventId);
      if (foundEventIndex !== -1) {
        if (action.payload.groups.includes('all')) {
          delete sportEventInfoList[foundEventIndex];
        }
      }
      return {
        ...state,
        sportEventInfoData: sportEventInfoList
      };
    }
    default:
      return state;
  }
};

export default sportsReducer;
