import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './Table.scss';

type TableProps = {
  fields: string[];
  items: any[];
};

const TableView: React.FC<TableProps> = props => {
  const { fields, items } = props;
  return (
    <TableContainer className="table-ctn">
      <Table className="table" size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {fields.map(row => (
              <TableCell key={row}>{row}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, index) => (
            <TableRow key={index}>
              {Object.values(row).map((c, index2) => (
                <TableCell component="th" scope="row" key={index2}>
                  {c ? c : '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableView;
