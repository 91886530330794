import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  personCircleOutline,
  refreshCircle,
  notificationsOutline,
} from 'ionicons/icons';
import { NavLink } from 'react-router-dom';

import './Header.scss';
import Modal from '../Modal/Modal';
import LoginForm from '../LoginForm/LoginForm';
import { RootState } from '../../models/RootState';
import { fetchBalance } from '../../store';
import { toggleDrawer } from '../../store';

type HeaderProps = {
  balance: number;
  fetchBalance: Function;
  isDrawerOpen: boolean;
  loggedIn: boolean;
  toggleDrawer: Function;
};

const Header: React.FC<HeaderProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle] = useState('Login');
  const { balance, fetchBalance, loggedIn, isDrawerOpen, toggleDrawer } = props;

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  const loginHandler = () => {
    setModalOpen(true);
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  const refreshBalanceHandler = () => {
    fetchBalance();
  };

  const handleDrawerOpen = () => {
    toggleDrawer(isDrawerOpen);
  };

  return (
    <>
      <IonHeader className="ds-view-ctn">
        <IonToolbar className="ion-toolbar">
          <IonTitle class="icon-title" slot="start" size="small">
            Adelson
          </IonTitle>

          <IonButtons className="nav-btns" slot="start">
            <NavLink activeClassName="active" to="/home" className="nav-link">
              <IonButton className="home-tab" color="">
                Home
              </IonButton>
            </NavLink>

            <NavLink
              activeClassName="active"
              to="/sports_book"
              className="nav-link"
            >
              <IonButton className="sport-book-tab" color="">
                Sports
              </IonButton>
            </NavLink>

            <NavLink
              activeClassName="active"
              to="/betgames"
              className="nav-link"
            >
              <IonButton className="live-games-tab" color="">
                Live Studio Games
              </IonButton>
            </NavLink>
          </IonButtons>

          <IonButtons slot="end">
            {loggedIn ? (
              <>
                <span>
                  Balance:{' '}
                  {balance !== null
                    ? parseFloat((balance / 100).toString()).toFixed(2)
                    : '-'}
                </span>
                <IonButton onClick={refreshBalanceHandler}>
                  <IonIcon slot="icon-only" icon={refreshCircle} />
                </IonButton>
                <IonButton>
                  <IonIcon icon={notificationsOutline} />
                </IonButton>
                <IonButton className="log-out-btn" onClick={handleDrawerOpen}>
                  <IonIcon icon={personCircleOutline}></IonIcon>
                </IonButton>
              </>
            ) : (
              <IonButtons slot="secondary">
                <IonButton
                  slot="end"
                  className="login-btn"
                  onClick={loginHandler}
                >
                  Login
                </IonButton>
                <IonButton slot="end" className="signup-btn">
                  Register
                </IonButton>
              </IonButtons>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Modal
        open={modalOpen}
        closeHandler={modalCloseHandler}
        title={modalTitle}
      >
        <LoginForm modalCloseHandler={modalCloseHandler}></LoginForm>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isDrawerOpen: state.common.isDrawerOpen,
    balance: state.auth.balance,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    toggleDrawer: (isDrawerOpen: boolean) =>
      dispatch(toggleDrawer(isDrawerOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
