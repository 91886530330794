import API from '../../api';
import {
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  AUTH_REQUEST_START,
  LOG_OUT,
} from './authActionTypes';

declare const localStorage: any;

const requestStart = () => {
  return {
    type: AUTH_REQUEST_START,
  };
};

const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  };
};

const loginFailed = (err: string) => {
  return {
    type: LOGIN_FAILED,
    payload: err,
  };
};

export const login = (username: string, password: string) => {
  return async (dispatch: Function) => {
    dispatch(requestStart());
    try {
      const response: any = await API.post('/users/login', {
        username,
        password,
      });
      dispatch(loginSuccess(response.data));
      localStorage.setItem('jwt_token', response.data.jwtToken);
      localStorage.setItem('bg_token', response.data.bgToken);
    } catch (err) {
      dispatch(loginFailed(err.message));
    }
  };
};

export const signup = (username: string, password: string) => {
  return async (dispatch: Function) => {
    dispatch(requestStart());
    try {
      const response = await API.post('/user', {
        username,
        password,
      });
      dispatch(loginSuccess(response.data));
      localStorage.setItem('bg_token', response.data.bgToken);
      localStorage.setItem('jwt_token', response.data.jwtToken);
    } catch (err) {
      dispatch(loginFailed(err.message));
    }
  };
};

export const logout = () => {
  localStorage.clear();
  return {
    type: LOG_OUT,
  };
};

const fetchBalanceSuccess = (balance: number) => {
  return {
    type: FETCH_BALANCE_SUCCESS,
    payload: balance,
  };
};

const fetchBalanceFailed = () => {
  return {
    type: FETCH_BALANCE_FAILED,
  };
};

export const fetchBalance = () => {
  return async (dispatch: Function) => {
    try {
      const response = await API.get('/user/balance', {
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
        },
      });
      dispatch(fetchBalanceSuccess(response.data.balance));
    } catch (err) {
      dispatch(fetchBalanceFailed());
      if (err.response.status === 401) {
        dispatch(logout());
      }
    }
  };
};
