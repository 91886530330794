import { AuthState } from '../../models/AuthState';
import {
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  AUTH_REQUEST_START,
  LOG_OUT,
} from './authActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initialState: AuthState = {
  balance: null,
  bgToken: localStorage.getItem('bg_token') || '-',
  jwtToken: localStorage.getItem('jwt_token') || '',
  loading: false,
  loginError: '',
  loggedIn: localStorage.getItem('jwt_token') ? true : false,
};

const authReducer = (state = initialState, action: Action): AuthState => {
  switch (action.type) {
    case AUTH_REQUEST_START:
      return {
        ...state,
        loading: true,
        loginError: '',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        bgToken: action.payload.bgToken,
        jwtToken: action.payload.jwtToken,
        loading: false,
        loginError: '',
        loggedIn: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        bgToken: '-',
        jwtToken: '',
        loading: false,
        loginError: action.payload,
        loggedIn: false,
      };
    case LOG_OUT:
      return {
        ...state,
        loggedIn: false,
        bgToken: '-',
        jwtToken: '',
      };
    case FETCH_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
      };
    case FETCH_BALANCE_FAILED:
      return {
        ...state,
        balance: null,
      };
    default:
      return state;
  }
};

export default authReducer;
