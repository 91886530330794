import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import commonReducer from './common/commonReducer';
import sportsBookReducer from './sportsBook/sportsBookReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  sportsBook: sportsBookReducer,
});

export default rootReducer;
