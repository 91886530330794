import React, { useState, useEffect, useCallback, createRef } from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';

import { RootState } from '../../models/RootState';

declare const window: any;

type PropType = {
  bgToken: string;
};

const BetGames: React.FC<PropType> = (props) => {
  const [bgJS] = useState(
    `https://integrations01.betgames.tv/design/client/js/betgames.js?ts=${Date.now()}`
  );
  const { bgToken } = props;
  const iframeRef = createRef<HTMLDivElement>();

  const renderIframe = useCallback(() => {
    const _bt = [];
    _bt.push(['server', 'https://integrations01.betgames.tv']);
    _bt.push(['partner', 'gridcsp']);
    _bt.push(['token', bgToken]);
    _bt.push(['language', 'en']);
    _bt.push(['timezone', '0']);
    if (window.BetGames) {
      while (iframeRef.current.firstChild)
        iframeRef.current.removeChild(iframeRef.current.firstChild);
      window.BetGames.frame(_bt);
    }
  }, [bgToken, iframeRef]);

  useEffect(() => {
    renderIframe();
  }, [renderIframe]);

  return (
    <>
      <Script url={bgJS} onLoad={renderIframe} />
      <div id="betgames_div_iframe" ref={iframeRef}></div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    bgToken: state.auth.bgToken,
  };
};

export default connect(mapStateToProps)(BetGames);
