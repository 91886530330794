import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../models/RootState';

type PrivateRouteProps = {
  component: React.FC;
  exact: boolean;
  loggedIn: boolean;
  path: string;
};

const PrivateRoute: React.FC<PrivateRouteProps> = props => {
  const { component, exact, loggedIn, path } = props;

  return loggedIn ? (
    <Route component={component} path={path} exact={exact} />
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
