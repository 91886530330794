import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router';
import { IonContent, IonSplitPane, IonMenu, IonSearchbar } from '@ionic/react';

import Spinner from '../../components/Spinner/Spinner';
import { Sport } from '../../models/Sport';
import { RootState } from '../../models/RootState';
import { SportEventInfoData } from '../../models/SportEventInfoData';
import { fetchMarketOdds, fetchSports } from '../../store';
import SportsListNav from '../../components/SportsListNav/SportsListNav';
import SportView from './SportView/SportView';
import SportHomeView from './SportHomeView/SportHomeView';
import './SportsBook.scss';

type SportsBookProps = {
  loggedIn: boolean;
  loading: boolean;
  errorMsg: string;
  fetchMarketOdds: Function;
  fetchSports: Function;
  sports: Sport[];
  sportEventInfoData: SportEventInfoData[];
};

const SportsBook: React.FC<SportsBookProps> = (props) => {
  const {
    errorMsg,
    fetchMarketOdds,
    fetchSports,
    loading,
    loggedIn,
    sports,
    sportEventInfoData,
  } = props;

  const { path } = useRouteMatch();

  useEffect(() => {
    fetchMarketOdds();
    fetchSports();
  }, [loggedIn, fetchMarketOdds, fetchSports]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <IonSplitPane
          when="md"
          content-id="main-content"
          className="ds-view-ctn"
        >
          <IonMenu className="sidebar" content-id="main-content">
            <IonContent>
              <IonSearchbar className="search-bar"></IonSearchbar>
              <SportsListNav listItems={sports} />
            </IonContent>
          </IonMenu>

          <div className="ion-page" id="main-content">
            <IonContent class="ion-padding">
              <Switch>
                <Route exact path={path}>
                  {errorMsg ? <h4>{errorMsg}</h4> : ''}
                  {sportEventInfoData.length > 0 ? (
                    <SportHomeView listItems={sportEventInfoData} />
                  ) : (
                    ''
                  )}
                </Route>

                <Route path={`${path}/:sid`}>
                  <SportView />
                </Route>
              </Switch>
            </IonContent>
          </div>
        </IonSplitPane>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
    sportEventInfoData: state.sportsBook.sportEventInfoData,
    sports: state.sportsBook.sports,
    errorMsg: state.sportsBook.errorMsg,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchMarketOdds: () => dispatch(fetchMarketOdds()),
    fetchSports: () => dispatch(fetchSports()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportsBook);
