import API from '../../api';
import { connectToWebsocket } from '../../websocket/websocket';
import {
  MARKET_ODDS_SUCCESS,
  REQUEST_FAILED,
  FEATCH_SPORTS_SUCCESS,
  REQUEST_START,
  ODDS_CHANGE,
  BET_STOP
} from './sportsBookActionTypes';
import { SportEventInfoData } from '../../models/SportEventInfoData';

declare const localStorage: any;

const requestStart = () => {
  return {
    type: REQUEST_START,
  };
};

const fetchMarketOddsSuccess = (response) => {
  return {
    type: MARKET_ODDS_SUCCESS,
    payload: response,
  };
};

const fetchSportsSuccess = (response) => {
  return {
    type: FEATCH_SPORTS_SUCCESS,
    payload: response,
  };
};

const fetchRequestFailed = (err: string) => {
  return {
    type: REQUEST_FAILED,
    payload: err,
  };
};

export const fetchMarketOdds = () => {
  return async (dispatch: Function) => {
    dispatch(requestStart);
    try {
      const response = await API.get('/SportEventInfo/sr:sport:1', {
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
        },
        params: {
          pageNo: 1,
          pageSize: 10
        }
      });
      if (response.status === 200) {
        dispatch(fetchMarketOddsSuccess(response.data.sportEventInfoData));
        connectToWebsocket();
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchSports = () => {
  return async (dispatch: Function) => {
    dispatch(requestStart);
    try {
      const response = await API.get('/sports', {
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
        },
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchSportsSuccess(response.data));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const updateSportEventInfo = (sportEventInfo: SportEventInfoData) => {
  console.log('in update');
  return {
    type: ODDS_CHANGE,
    payload: sportEventInfo
  };
};

export const betStop = (eventId: string, groups: string[]) => {
  return {
    type: BET_STOP,
    payload: {
      eventId,
      groups
    }
  };
};
