import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import { SportEventInfoData, MarketOddsData } from '../models/SportEventInfoData';
import { updateSportEventInfo } from '../store';

import store from '../store/store';

const stompClient = null;

export const connectToWebsocket = () => {
  const socket = new SockJS('/adelson-ws');
  const stompClient = Stomp.over(socket);
  stompClient.connect({}, function (frame) {
    console.log('Connected: ' + frame);
    console.log('connected');

    stompClient.subscribe('/topic/market_odds', function (marketOdds) {
      oddsChangeHandler(marketOdds);
      //stv
    });
    stompClient.subscribe('/topic/fixture_change', function (marketOdds) {
      console.log('FIXTURE CAHGNE');
      //stv
    });
    stompClient.subscribe('/topic/bet_stop', function (marketOdds) {
      setupResponse(marketOdds);
      //stv
    });
    stompClient.subscribe('/topic/bet_settlement', function (marketOdds) {
      setupResponse(marketOdds);
      //stv
    });
  });
};

export const disconnect = () => {
  if (stompClient !== null) {
    stompClient.disconnect();
  }
  console.log('Disconnected');
};

const setupResponse = (data) => {
  console.log(data);
  console.log(JSON.parse(data.body));
  //let sevents: SportEventInfoData[];
};

const oddsChangeHandler = (changeData) => {
  const changeObj = JSON.parse(changeData.body);
  console.log(changeObj);

  const marketDataList: MarketOddsData[] = [];

  for (const marketData of changeObj.marketOddsInfoSet) {
    const md: MarketOddsData = {
      isActive: marketData.isActive,
      marketDesc: marketData.marketDesc,
      marketId: marketData.marketId,
      oddsInfo: JSON.parse(marketData.oddsData)
    };

    marketDataList.push(md);
  }

  const sportEventInfo: SportEventInfoData = {
    awayScore: changeObj.sportEventInfo.awayScore,
    eventId: changeObj.sportEventInfo.eventId,
    eventName: changeObj.sportEventInfo.eventName,
    homeScore: changeObj.sportEventInfo.homeScore,
    live: changeObj.sportEventInfo.isLive,
    sportId: changeObj.sportEventInfo.sportId,
    scheduledTime: changeObj.sportEventInfo.scheduledTime,
    scheduledEndTime: changeObj.sportEventInfo.scheduledEndTime,
    tournamentName: changeObj.sportEventInfo.tournamentName,
    tournamentId: changeObj.sportEventInfo.tournamentId,
    marketOddsDataSet: marketDataList
  };

  console.log('=========================');
  console.log(sportEventInfo);
  console.log('=========================');
  store.dispatch(updateSportEventInfo(sportEventInfo));
};
