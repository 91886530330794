import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Switch } from 'react-router';

import Home from './pages/Home/Home';
import BetGames from './pages/BetGames/BetGames';
import Ledger from './pages/Ledger/Ledger';
import Users from './pages/Users/UserManagement';
import Header from './components/Header/Header';
import Drawer from './components/Drawer/Drawer';
import SportsBook from './pages/SportsBook/SportsBook';
import PrivateRoute from './hoc/ProtectedRoute';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Header></Header>
      <Drawer></Drawer>
      <div className="router-ctn">
        <Switch>
          <Route path="/home" component={Home} exact={true} />
          <Route path="/betgames" component={BetGames} exact={true} />
          <Route path="/sports_book" component={SportsBook}></Route>
          <PrivateRoute
            path="/ledger"
            component={Ledger}
            exact={true}
          ></PrivateRoute>
          <PrivateRoute
            path="/user_management"
            component={Users}
            exact={true}
          ></PrivateRoute>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </Switch>
      </div>
    </IonReactRouter>
  </IonApp>
);

export default App;
