import { TOGGLE_DRAWER } from './commonActionTypes';
import { CommonState } from '../../models/CommonState';

type Action = {
  type: string;
  payload: any;
};

const initialState: CommonState = {
  isDrawerOpen: false,
};

const commonReducer = (state = initialState, action: Action): CommonState => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
