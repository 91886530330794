import React from 'react';
import moment from 'moment';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { SportEventInfoData } from '../../../models/SportEventInfoData';
import { SPORT_HOME_TB_HEADERS } from '../../../constants/SportHomeView';
import './SportHomeView.scss';

type PropType = {
  listItems: SportEventInfoData[];
};

const SportHomeView: React.FC<PropType> = (props) => {
  const { listItems } = props;

  return (
    <div className="sp-landing-page-ctn">
      <IonCard className="add-banner">
        <IonCardHeader>
          <IonCardTitle>10 Poins Bonus!</IonCardTitle>
          <IonCardSubtitle>
            Get a bonus of 10 points on your every 5thwagerduring this Aisa cup
            season
          </IonCardSubtitle>
        </IonCardHeader>
      </IonCard>

      <h5>Trending</h5>

      {listItems.length > 0 ? (
        <>
          {console.log(listItems)}
          <TableContainer>
            <Table className="table" aria-label="simple table">
              <TableHead>
                <TableRow>
                  {SPORT_HOME_TB_HEADERS.map((h, key) => (
                    <TableCell align="justify" key={key}>
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listItems.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <div className="tournament-name">
                        {row.tournamentName}
                      </div>
                      <div>
                        {moment(row.scheduledTime).format('DD-MM, h:mm')}
                      </div>
                    </TableCell>
                    <TableCell align="justify">
                      {row.eventName.split('vs.').map((i, key) => {
                        return (
                          <div className="event-name" key={key}>
                            {i}
                          </div>
                        );
                      })}
                    </TableCell>
                    {row.marketOddsDataSet.map((o, index2) => (
                      <>
                        {o.marketDesc === '1x2' ? (
                          <>
                            {o.oddsInfo.map((odd, index3) => (
                              <TableCell key={index3} align="center">
                                <div className="odd-selection">
                                  {odd.oddsValue.toFixed(2)}
                                </div>
                              </TableCell>
                            ))}
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ))}

                    {row.marketOddsDataSet.length === 0 ? (
                      <>
                        <TableCell align="center">
                          <div className="odd-selection">-</div>
                        </TableCell>

                        <TableCell align="center">
                          <div className="odd-selection">-</div>
                        </TableCell>

                        <TableCell align="center">
                          <div className="odd-selection">-</div>
                        </TableCell>

                        <TableCell align="center">
                          <div className="odd-selection">0</div>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell align="center">
                        <div className="odd-selection">
                          + {row.marketOddsDataSet.length - 1}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default SportHomeView;
