import React from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { RootState } from '../../models/RootState';
import { toggleDrawer } from '../../store';
import NavList from '../NavList/NavList';
import { DRAWER_NAV_LIST } from '../../constants/DrawerItems';
import './Drawer.scss';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

type PropType = {
  isDrawerOpen: boolean;
  toggleDrawer: Function;
};

const BetGames: React.FC<PropType> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isDrawerOpen, toggleDrawer } = props;

  const handleDrawer = () => {
    toggleDrawer(false);
  };

  return (
    <>
      <div className="drawer-ctn">
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={isDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className="drawer-header">
            <IconButton className="toggle-btn" onClick={handleDrawer}>
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <NavList navListItems={DRAWER_NAV_LIST} />
        </Drawer>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isDrawerOpen: state.common.isDrawerOpen,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    toggleDrawer: (isDrawerOpen: boolean) =>
      dispatch(toggleDrawer(!isDrawerOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetGames);
