export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const FETCH_MARKET_ODDS_SUCCESS = 'TOGGLE_DRAWER';
export const FETCH_MARKET_ODDS_FAILED = 'FETCH_MARKET_ODDS_FAILED';
export const MARKET_ODDS_SUCCESS = 'MARKET_ODDS_SUCCESS';
export const MARKET_ODDS_FAILED = 'MARKET_ODDS_FAILED';
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const FEATCH_SPORTS_SUCCESS = 'FEATCH_SPORTS_SUCCESS';
export const ODDS_CHANGE = 'ODDS_CHANGE';
export const BET_STOP = 'BET_STOP';
