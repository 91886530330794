import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import TableView from '../../components/Table/Table';
import API from '../../api/index';
import { logout } from '../../store';
import { LedgerRecord } from '../../models/LedgerRecord';
import Spinner from '../../components/Spinner/Spinner';

type LedgerProps = {
  logout: Function;
};

const Ledger: React.FC<LedgerProps> = (props) => {
  const { logout } = props;
  const [errorMsg, setErrorMsg] = useState(null);
  const [progress, setProgress] = useState(false);
  const [ledgerRecords, setLedgerRecords] = useState<LedgerRecord[]>([]);
  const [fields] = useState([
    'Transaction ID',
    'User Name',
    'Bet',
    'Payin Amt',
    'Bet Time',
    'Draw Time',
    'ODD',
    'Payout Amt',
    'Bet Type',
  ]);

  const fetchRecords = useCallback(async () => {
    try {
      setProgress(true);
      const response: any = await API.get('/account/transactions', {
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        setLedgerRecords(response.data);
        setProgress(false);
      } else {
        throw new Error(response);
      }
    } catch (err) {
      setErrorMsg(err.response.data.error);
      setProgress(false);
      if (err.response && err.response.status === 401) {
        logout();
      }
    }
  }, [logout]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <div>
      {progress ? <Spinner /> : ''}
      {errorMsg && !progress ? (
        <h4>{errorMsg}</h4>
      ) : (
        <TableView fields={fields} items={ledgerRecords} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Ledger);
